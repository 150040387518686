import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, logoutUser } from '@store/reducers/auth';
import { toggleSidebarMenu } from '@app/store/reducers/ui';

// Pages
import Dashboard from '@pages/Dashboard';
import Blank from '@pages/Blank';
import SubMenu from '@pages/SubMenu';
import Profile from '@pages/profile/Profile';
// Users
import Users from '@pages/users/Users';
import CreateUsers from '@pages/users/Create';
import EditUsers from '@pages/users/Edit';
import Clients from '@pages/clients/Clients';
import CreateClients from '@pages/clients/Create';
import EditClients from '@pages/clients/Edit';
// News
import News from '@pages/news/News';
import CreateNews from '@pages/news/Create';
import EditNews from '@pages/news/Edit';
import Tags from '@pages/news/Tags';
import CreateTag from '@pages/news/CreateTag';
import EditTag from '@pages/news/EditTag';
// Reports
import Reports from '@pages/reports/Reports';
// Donations
import Donations from '@pages/donations/Donations';
import CreateDonation from '@pages/donations/Create';
import EditDonation from '@pages/donations/Edit';
// Rewards
import Rewards from '@pages/rewards/Rewards';
import CreateRewards from '@pages/rewards/Create';
import EditRewards from '@pages/rewards/Edit';
import Redeem from '@pages/rewards/Redeem';
import RewardRedeem from '@pages/rewards/RewardRedeem';
// Tickeets
import TicketScan from '@pages/ticketScan/TicketScan';
import CreateTicket from '@pages/ticketScan/Create';
import EditTicket from '@pages/ticketScan/Edit';
import ProductList from '@pages/ticketScan/ProductList';
import Points from '@pages/ticketScan/Points';
// Notifications
import Notifications from '@pages/notifications/Notifications';
import CreateNotification from '@pages/notifications/Create';
import EditNotification from '@pages/notifications/Edit';
// Categories
import Categories from '@pages/categories/Index';
import CategoriesEdit from '@pages/categories/Edit';
// Faqs
import Questions from '@pages/faqs/Index';
// Faqs
import Terms from '@pages/terms/Index';
// Configuration
import ConfigurationEdit from '@pages/configuration/Edit'
// Promotions
import Promotions from '@pages/promotions/Promotions'
import CreatePromotion from '@pages/promotions/Create'
import EditPromotion from '@pages/promotions/Edit'
import ReportsPromotion from '@pages/promotions/Reports'
import ReportsPoints from '@pages/promotions/ReportsPoints'

import Header from './header/Header';
import Footer from './footer/Footer';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import PageLoading from '../../components/page-loading/PageLoading';

const Main = () => {
    const dispatch = useDispatch();
    const isSidebarMenuCollapsed = useSelector(
        (state) => state.ui.isSidebarMenuCollapsed
    );
    const screenSize = useSelector((state) => state.ui.screenSize);
    const [isAppLoaded, setIsAppLoaded] = useState(false);

    const handleToggleMenuSidebar = () => {
        dispatch(toggleSidebarMenu());
    };

    const fetchProfile = async () => {
        try {
            const response = 'test';
            dispatch(loadUser(response));
            setIsAppLoaded(true);
        } catch (error) {
            dispatch(logoutUser());
            setIsAppLoaded(true);
        }
    };

    const getRol = JSON.parse(localStorage.getItem('datauser'))
    const rolUser = (getRol.isSuperAdmin)

    useEffect(() => {
        document.getElementById('root').classList.remove('register-page');
        document.getElementById('root').classList.remove('login-page');
        document.getElementById('root').classList.remove('hold-transition');

        document.getElementById('root').classList.add('sidebar-mini');
        document.getElementById('root').classList.add('layout-fixed');

        fetchProfile();
        return () => {
            document.getElementById('root').classList.remove('sidebar-mini');
            document.getElementById('root').classList.remove('layout-fixed');
        };
    }, []);

    useEffect(() => {
        document.getElementById('root').classList.remove('sidebar-closed');
        document.getElementById('root').classList.remove('sidebar-collapse');
        document.getElementById('root').classList.remove('sidebar-open');
        if (isSidebarMenuCollapsed && screenSize === 'lg') {
            document.getElementById('root').classList.add('sidebar-collapse');
        } else if (isSidebarMenuCollapsed && screenSize === 'xs') {
            document.getElementById('root').classList.add('sidebar-open');
        } else if (!isSidebarMenuCollapsed && screenSize !== 'lg') {
            document.getElementById('root').classList.add('sidebar-closed');
            document.getElementById('root').classList.add('sidebar-collapse');
        }
    }, [screenSize, isSidebarMenuCollapsed]);

    const getAppTemplate = useCallback(() => {
        if (!isAppLoaded) {
            return <PageLoading />;
        }

        if (rolUser) {
            return (
                <>
                    <Header toggleMenuSidebar={handleToggleMenuSidebar} />

                    <MenuSidebar />

                    <div className="content-wrapper">
                        <div className="pt-3" />
                        <section className="content">
                            <Switch>
                                <Route exact path="/sub-menu-2" component={Blank} />
                                <Route
                                    exact
                                    path="/sub-menu-1"
                                    component={SubMenu}
                                />
                                <Route exact path="/blank" component={Blank} />
                                <Route exact path="/profile" component={Profile} />
                                <Route exact path="/users" component={Users} />
                                <Route exact path="/users/create" component={CreateUsers} />
                                <Route exact path="/users/edit/:id" component={EditUsers} />
                                <Route exact path="/users/delete/:id" component={Users} />
                                <Route exact path="/clients" component={Clients} />
                                <Route exact path="/clients/create" component={CreateClients} />
                                <Route exact path="/clients/edit/:uid" component={EditClients} />
                                <Route exact path="/clients/delete/:uid" component={Clients} />
                                <Route exact path="/news" component={News} />
                                <Route exact path="/news/create" component={CreateNews} />
                                <Route exact path="/news/edit/:id" component={EditNews} />
                                <Route exact path="/news/delete/:id" component={News} />
                                <Route exact path="/news/categories" component={Tags} />
                                <Route exact path="/news/categories/create" component={CreateTag} />
                                <Route exact path="/news/categories/edit/:id" component={EditTag} />
                                <Route exact path="/news/Tags/delete/:id" component={Tags} />
                                <Route exact path="/donations/" component={Donations} />
                                <Route exact path="/donations/create" component={CreateDonation} />
                                <Route exact path="/donations/edit/:id" component={EditDonation} />
                                <Route exact path="/rewards" component={Rewards} />
                                <Route exact path="/rewards/create" component={CreateRewards} />
                                <Route exact path="/rewards/edit/:id" component={EditRewards} />
                                <Route exact path="/rewards/redeem" component={Redeem} />
                                <Route exact path="/rewards/rewardredeem/:idUser/:idRedeem" component={RewardRedeem} />
                                <Route exact path="/tickets" component={TicketScan} />
                                <Route exact path="/ticketScan/create/:id" component={CreateTicket} />
                                <Route exact path="/ticketScan/ProductList/:id" component={ProductList} />
                                <Route exact path="/ticketScan/Points" component={Points} />
                                <Route exact path="/ticketScan/edit/:id" component={EditTicket} />
                                <Route exact path="/categories" component={Categories} />
                                <Route exact path="/categories/edit/:id" component={CategoriesEdit} />
                                <Route exact path="/faqs" component={Questions} />
                                <Route exact path="/terms" component={Terms} />
                                <Route exact path="/notifications" component={Notifications} />
                                <Route exact path="/notifications/Create" component={CreateNotification} />
                                <Route exact path="/notifications/edit/:id" component={EditNotification} />
                                <Route exact path="/promotions/" component={Promotions} />
                                <Route exact path="/promotions/create" component={CreatePromotion} />
                                <Route exact path="/promotions/edit/:id" component={EditPromotion} />
                                <Route exact path="/promotions/reports" component={ReportsPromotion} />
                                <Route exact path="/promotions/reports-points" component={ReportsPoints} />
                                
                                <Route exact path="/configuration/system" component={ConfigurationEdit} />
                                <Route exact path="/" component={Reports} />
                            </Switch>
                        </section>
                    </div>
                    <Footer />
                    <div
                        id="sidebar-overlay"
                        role="presentation"
                        onClick={handleToggleMenuSidebar}
                        onKeyDown={() => { }}
                    />
                </>
            );
        } else {
            return (
                <>
                    <Header toggleMenuSidebar={handleToggleMenuSidebar} />

                    <MenuSidebar />

                    <div className="content-wrapper">
                        <div className="pt-3" />
                        <section className="content">
                            <Switch>
                                <Route exact path="/blank" component={Blank} />
                                <Route exact path="/profile" component={Profile} />
                                <Route exact path="/clients" component={Clients} />
                                <Route exact path="/clients/create" component={CreateClients} />
                                <Route exact path="/clients/edit/:uid" component={EditClients} />
                                <Route exact path="/clients/delete/:uid" component={Clients} />
                                <Route exact path="/news" component={News} />
                                <Route exact path="/news/create" component={CreateNews} />
                                <Route exact path="/news/edit/:id" component={EditNews} />
                                <Route exact path="/news/delete/:id" component={News} />
                                <Route exact path="/news/categories" component={Tags} />
                                <Route exact path="/news/categories/create" component={CreateTag} />
                                <Route exact path="/news/categories/edit/:id" component={EditTag} />
                                <Route exact path="/news/Tags/delete/:id" component={Tags} />
                                <Route exact path="/donations/" component={Donations} />
                                <Route exact path="/donations/create" component={CreateDonation} />
                                <Route exact path="/donations/edit/:id" component={EditDonation} />
                                <Route exact path="/rewards" component={Rewards} />
                                <Route exact path="/rewards/create" component={CreateRewards} />
                                <Route exact path="/rewards/edit/:id" component={EditRewards} />
                                <Route exact path="/rewards/redeem" component={Redeem} />
                                <Route exact path="/rewards/rewardredeem/:idUser/:idRedeem" component={RewardRedeem} />
                                <Route exact path="/tickets" component={TicketScan} />
                                <Route exact path="/ticketScan/create/:id" component={CreateTicket} />
                                <Route exact path="/ticketScan/ProductList/:id" component={ProductList} />
                                <Route exact path="/ticketScan/Points" component={Points} />
                                <Route exact path="/ticketScan/edit/:id" component={EditTicket} />
                                <Route exact path="/notifications" component={Notifications} />
                                <Route exact path="/notifications/Create" component={CreateNotification} />
                                <Route exact path="/notifications/edit/:id" component={EditNotification} />
                                <Route exact path="/" component={Reports} />
                                <Route exact path="/promotions/" component={Promotions} />
                                <Route exact path="/promotions/create" component={CreatePromotion} />
                                <Route exact path="/promotions/reports" component={ReportsPromotion} />
                                <Route exact path="/promotions/reports-points" component={ReportsPoints} />

                            </Switch>
                        </section>
                    </div>
                    <Footer />
                    <div
                        id="sidebar-overlay"
                        role="presentation"
                        onClick={handleToggleMenuSidebar}
                        onKeyDown={() => { }}
                    />
                </>
            );
        }
    }, [isAppLoaded]);

    return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
