import React, { useState, useEffect, useMemo } from 'react';
import { ContentHeader, Button } from '@components';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as usersService from '../../services/notifications';
import { toast } from 'react-toastify';
import Api from '../../utils/axios'
import prettyDate from '../../utils/pretty-date'
import { setLoading } from '@app/store/reducers/ui';

import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';

const headerButtons = [
    {
        text: "Agregar nueva notificación",
        urlSlug: "/notifications/create",
    }
];

const Notifications = () => {

    const [t] = useTranslation();
    const [notifications, setNotifications] = useState([])
    const api = new Api();
    const history = useHistory();
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{t('notifications.label.title')}</b>,
            selector: row => row.title,
            sortable: true,
        },
        {
            name: <b>{t('notifications.label.typeNotification')}</b>,
            selector: row => row.notificationType.name,
            sortable: true,
        },
        {
            name: <b>{t('general.label.dateOfCreated')}</b>,
            selector: row => row.createdAt,
            sortable: true,
            format: row => prettyDate(row.createdAt),
        },
        {
            name: <b> </b>,
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => handleButtonClick(row)} id={row.id} >
                <i className="icon-button fa fa-trash"></i>
            </Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    const handleButtonClick = async (item) => {
        await api.deleteNotificationWeb(item?.id).then(()=> {
            usersService.getNotifications()
            .then(response => {
                setNotifications(response)
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            })            
        });
    };

    useEffect(() => {
        dispatch(setLoading(true));
            usersService.getNotifications()
                .then(response => {
                    setNotifications(response)
                    dispatch(setLoading(false));
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setLoading(false));
                })
    }, [])

    return (
        <>
        <div id="news-list">
            <ContentHeader title={t('menusidebar.label.notifications')} buttons={headerButtons} />
                { notifications && notifications.length > 0 ?
                    <DataTable
                        data={notifications}
                        customStyles={customStyles}
                        progressPending={loading}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                    : <div>
                        <h1 className="text-center">{t('general.label.noRegisters')}</h1>
                    </div>}
            </div>
            </>
    );
};

export default Notifications;