import React, { useState, useEffect } from 'react';
import { ContentHeader, Button, Input, ComboBox } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as RewardRedeemService from '../../services/rewords';
import prettyDateOnly from '../../utils/pretty-date'
import prettyOnlyDate from '../../utils/pretty-date'
import * as Yup from 'yup';
import Api from '../../utils/axios'
import './RewardRedeem.css';
import { Modal } from 'react-bootstrap';



const RewardRedeem = () => {
    const api = new Api();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [rewardUser, setRewardUser] = useState([]);
    const [points, setPoints] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const [address, setAddress] = useState(false);
    const [status, setStatus] = useState();
    const [t] = useTranslation();
    const { idRedeem  } = useParams()
    const { idUser } = useParams();


    useEffect(() => {
        if (loading) {
            RewardRedeemService.getByIdClientIdRedeem(idUser, idRedeem)
                .then((response) => {
                    setRewardUser([response.data])
                    console.log(response.data)
                    setStatus(response.data.status)
                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                    console.log(error)
                })
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            estimatedDeliveryDate: '',
            status: 0,

        },
        validationSchema: Yup.object({
            estimatedDeliveryDate: Yup.string()
                .required('Required')
        }),
        onSubmit: (values) => {
            console.log("values",values);
            //rewardRedeemData(values);
        }
    });

    const rewardRedeemData = async (data) => {
        try {
            const response = await RewardRedeemService.patchRewords(idUser, idRedeem, data);
            console.log(response)
            toast.success(t('rewards.label.claimedBounty'));
            history.push('/rewards/redeem');
        } catch (error) {
            setRewardUser(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

        if (status === 1) {
            // document.querySelector(".inputDate").style.display  = "none";
            // document.querySelector(".btn-assign-date").style.display  = "none";
            // document.querySelector(".header-date").style.display  = "none";
    }
        
    console.log("formik", formik)
    return (
        <>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('rewards.label.infoClient')}</h3>
                    </div>
                        <div className="p-3">
                        {rewardUser && (rewardUser.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="form-row">
                                        
                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.clientId')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.clientId}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('general.label.name')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.client.name}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>{t('general.label.lastName')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.client.lastName}
                                            />
                                        </div>


                                    </div>

                                    <div className="form-row mt-3">

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.street')}</label>
                                            <input
                                                disabled
                                                value={item.addressDelivery.street}
                                                className="form-control"
                                                placeholder="Calle" />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.externalNumber')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.externalNumber}
                                                placeholder="Número Externo" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.InternalNumber')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.internalNumber === null ? '-' : item.addressDelivery.internalNumber}
                                                placeholder={t('address.label.InternalNumberOptional')} />
                                        </div>


                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.postalCode')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.postalCode}
                                                placeholder="Código Postal"
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.city')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.city}
                                                placeholder="Ciudad" />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.country')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.country}
                                                placeholder="País" />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('address.label.state')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.state}
                                                placeholder="Estado" />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label> {t('address.label.hometown')} </label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.hometown}
                                                placeholder="Municipio" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label> {t('address.label.phone')} </label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.addressDelivery.phone}
                                                placeholder="Telefono" />
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.pointsClient')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.client.pointValue}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.redeemedPoints')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.pointRedeemed}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.rewardName')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={item.reward.name}
                                            />
                                        </div>



                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.status')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={
                                                    item.status > 0 ? 'Enviada' : 'Pendiente'
                                                }
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>{t('rewards.label.updateDate')}</label>
                                            <input
                                                disabled
                                                className="form-control"
                                                value={prettyOnlyDate(item.updatedAt)}
                                            />
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label className="mr-3" >{t('rewards.label.imageReward')}</label>
                                            <img src={item.url} alt="image" height={100} width={100} />
                                        </div>



                                    </div>

                                </div>
                            )
                            }))}
                        </div>
                </div>

                <div className="card">

                    <div className="card-header header-date -p2">
                        <h3 className="text-danger font-weight-bold">{t('rewards.label.deliveryDate')} y estatus</h3>
                        
                    </div>
                    
                    <div className="form-row mt-3" >
                        <div className="form-group col-md-9" >
                            <div className="p-3">
                                <form className="form-horizontal form-date-reward" onSubmit={formik.handleSubmit}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            
                                            <Input
                                                className="inputDate mb-3"
                                                id="dateInput"
                                                min={disablePastDate()}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('estimatedDeliveryDate')}
                                                type="date"
                                                placeholder={t('rewards.label.deliveryDate')} />
                                            <ComboBox
                                                id="status"
                                                items={[
                                                    {id: 0, value: "Pendiente"},
                                                    {id: 1, value: "Enviada"},
                                                    {id: 2, value: "Cancelado"}
                                                ]}
                                                optionTitle="value"
                                                // onChange={ (e) =>
                                                //     formik?.setFieldValue("status",e?.target?.value)
                                                // }
                                                value={formik.values.status}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps(
                                                    'status'
                                                )}
                                                placeholder="Status"
                                            />
                                        </div>
                                    </div>
                                    <div className="row p-2 pt-4">
                                        <div className="col-2">
                                            <Link
                                                to={`/rewards/redeem`}
                                                className="btn text-danger btn-cancel" > {t('general.buttons.cancel')} </Link>
                                        </div>
                                        <div className="col-sm-2">
                                            <Button
                                                type="submit" block
                                                className="btn btn-danger btn-assign-date" > {t('general.buttons.assignDate')} </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="form-group col-md-3 font-italic" >
                            <p> <p className="font-weight-bold" ></p> {t('rewards.label.text1')}<p className="font-weight-bold"> {t('rewards.label.text2Important')} </p></p>
                        </div>
                    </div>


                    
                </div>
            </div>
        </>
    );
};

export default RewardRedeem;