import React, { useState, useEffect, useMemo } from 'react';
import { ContentHeader, Button, FilterComponent } from '@components';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import * as usersService from '../../services/rewords';
import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';

const headerButtons = [
    {
        text: "Agregar nueva recompensa",
        urlSlug: "/rewards/create",
    }
];

const Rewards = () => {
    const [t] = useTranslation();
    const [rewards, setRewards] = useState([])
    const history = useHistory();
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>Fecha de creación</b>,
            selector: row => row.createdAt?.substring(0,10),
            sortable: true,
        },
        {
            name: <b>{ t('general.label.name')}</b>,
            selector: row => row.name,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{ t('general.label.points')}</b>,
            selector: row => row.value,
            sortable: true,
        },
        {
            name: <b>{t('rewards.label.existence')}</b>,
            selector: row => row.stock,
            sortable: true,
        },
        {
            name: <b>{t('rewards.label.category')}</b>,
            selector: row => row.category.name,
            sortable: true,
        },
        {
            name: <b>{t('rewards.label.subcategory')}</b>,
            selector: row => row.subCategory ? row.subCategory.name : '',
            sortable: true,
        },
        {
            name: '',
            cell: (row) => <Button className="mr-1 btn btn-danger" onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    // Hooks para el filtro de la tabla
    const [filterName, setFilterName] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = rewards.filter(
        item => (item.name && item.name.toLowerCase().includes(filterName.toLowerCase())) &&
            (item.category && item.category.name.toLowerCase().includes(filterCategory.toLowerCase())),
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterCategory) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterCategory('');
            }


            if (filterName) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterName('');
            }
        };

        return (
            <div className='d-flex p-2'>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Nombre' onFilter={e => setFilterName(e.target.value)} filterText={filterName} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Categoría' onFilter={e => setFilterCategory(e.target.value)} filterText={filterCategory} />
                </div>
                <div className="p-2">
                    <Button className="btn btn-danger" type="button" onClick={handleClear}>
                        Limpiar
                    </Button>
                </div>
            </div>
        );
    }, [filterCategory, filterName, resetPaginationToggle]);

    useEffect(() => {
        dispatch(setLoading(true));
            usersService.getRewords()
                .then(response => {
                    setRewards(response)
                    dispatch(setLoading(false));
                })
                .catch(err => {
                    dispatch(setLoading(false));
                })
        
    }, [])

    const handleButtonClick = (item) => {
        history.push(`/rewards/edit/${item.id}`)
    };

    return (
        <div id="news-list">
            <ContentHeader  title={t('menusidebar.label.rewards')} buttons={headerButtons} />
            {rewards && rewards.length > 0 ? (

                <DataTable
                    data={filteredItems}
                    customStyles={customStyles}
                    progressPending={loading}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    noDataComponent="No se encuentran registros"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover={true}
                    columns={columns}
                />
            ) : <div>
                    <h1 className="text-center">{t('general.label.noRegisters')}</h1>
            </div>}
        </div>
    );
};

export default Rewards;