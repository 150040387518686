import Api from '../utils/axios';
const api = new Api();


export const getRewords = async () => {
    const rewords = await api.getRewords()
    return rewords.data;
}

export const getSubCategories = async (categoryId) => {
    const response = await api.getSubCategories(categoryId)
    console.log(response)
    return response;
}

export const getRewordsRedeem = async () => {
    const redeem = await api.getRewordsRedeem();
    return redeem.data
}

export const getRewardsRedeemDigitals = async () => {
    const response = await api.getRewardsRedeemDigitals();
    return response.data
}

export const getByIdClientIdRedeem = async (idUser, idRedeem ) => {
    const result = await api.getByIdClientIdRedeem(idUser, idRedeem );
    return result;
}

export const patchRewords = async (clientId, id, data) => {
    const result = await api.patchRewords(clientId, id, data);
    console.log(result);
    return result.data;
}

export const createReword = async (data) => {
    const CreatedReword = await api.createReword(data);
    return CreatedReword;
}

export const deleteReword = async (id) => {
    const deleteRewords = await api.deleteReword(id);
    return deleteRewords.data;
}

export const updateRewords = async (id, data) => {
    const updateReword = await api.updateRewords(id, data);
    return updateReword;
}

export const getRewordsById = async (id) => {
    const response = await api.getRewordsById(id);
    return response
}

export const getReport = async (id) => {
    const response = await api.getReportBase64();
    return response
}